import { Typography } from '@mui/material';

const PostContent3 = () => {

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Como a Energia Solar Reduz as Emissões de Carbono
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Publicado em: 2024-08-23
      </Typography>
      <Typography variant="body1" paragraph>
        A energia solar desempenha um papel crucial na redução das emissões de carbono, uma vez que substitui o uso de combustíveis fósseis, como carvão e gás natural, que são as principais fontes de emissão de CO2. Estudos mostram que a implantação de energia solar em larga escala pode reduzir significativamente a pegada de carbono global, contribuindo para mitigar os impactos das mudanças climáticas.
      </Typography>
      <Typography variant="body1" paragraph>
        Ao gerar eletricidade a partir do sol, as usinas solares evitam a queima de combustíveis fósseis que, além de emitir dióxido de carbono, liberam outras substâncias tóxicas no ar, como dióxido de enxofre e óxidos de nitrogênio. Essas emissões são responsáveis por uma série de problemas ambientais, incluindo a acidificação dos oceanos e a poluição atmosférica.
      </Typography>
      <Typography variant="body1" paragraph>
        Outro ponto importante é a durabilidade e a eficiência dos painéis solares, que têm uma vida útil de cerca de 25 a 30 anos. Durante esse período, cada painel instalado evitará a emissão de toneladas de CO2. Além disso, o processo de fabricação e instalação de sistemas solares está se tornando cada vez mais eficiente, o que significa que o impacto ambiental associado à produção desses sistemas está diminuindo.
      </Typography>
      <Typography variant="body1" paragraph>
        A expansão da energia solar também cria empregos verdes e incentiva o desenvolvimento de tecnologias de energia limpa, que são fundamentais para a transição para uma economia de baixo carbono. Ao investir em energia solar, estamos não apenas reduzindo as emissões, mas também promovendo um futuro mais sustentável para as próximas gerações.
      </Typography>
    </div>
  );
};

export default PostContent3;
