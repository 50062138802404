import React from 'react';
import { Box, Typography, Card, CardContent, List, ListItem, ListItemText } from '@mui/material';

const updates = [
  {
    title: 'Versão inicial',
    date: '2024-09-01T00:00:00', // Formato ISO com hora para evitar problemas de fuso horário
    changes: [
      'Versão inicial.',
    ],
  },
  {
    title: 'Melhorias do cálculo de economia anual e Payback',
    date: '2024-09-09T23:00:00', // Formato ISO com hora para evitar problemas de fuso horário
    changes: [
      'Inclusão de tarifa mínima de 30 KWh no cálculo de economia anual.',
      'Consideração de taxa de iluminação pública no cálculo de economia anual.',
      'Correções no cálculo de Payback simples.',
    ],
  }
];

// Função para ordenar as atualizações por data de forma decrescente
const sortUpdatesByDate = (updates) => {
  return updates.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
};

const RecentUpdates = () => {
  const sortedUpdates = sortUpdatesByDate(updates);

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2}}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Últimas Atualizações no Site
        </Typography>

        {sortedUpdates.map((update, index) => (
          <Box key={index} sx={{ marginBottom: 3 }}>
            <Typography variant="h6">{update.title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {new Date(update.date).toLocaleDateString('pt-BR')}
            </Typography>

            <List>
              {update.changes.map((change, changeIndex) => (
                <ListItem key={changeIndex}>
                  <ListItemText primary={change} />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default RecentUpdates;