import React from 'react';
import { Typography, Paper, List, ListItem, ListItemText, Divider, ListItemIcon } from '@mui/material';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import NatureIcon from '@mui/icons-material/Nature';
import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const BlogCategories = ({ onSelectCategory }) => {
  // Mapeamento das categorias para os ícones correspondentes
  const categories = [
    { name: 'Tecnologia Solar Fotovoltaica', icon: <SolarPowerIcon /> },
    { name: 'Sustentabilidade', icon: <NatureIcon  /> },
    { name: 'Políticas e Incentivos Governamentais', icon: <GavelIcon /> },
    { name: 'Economia e Investimento', icon: <AttachMoneyIcon /> }
  ];

  return (
    <Paper sx={{ padding: '20px' }}>
      <Typography variant="h6">Categorias</Typography>
      <List>
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => onSelectCategory(category.name)}>
              <ListItemIcon>
                {category.icon}
              </ListItemIcon>
              <ListItemText primary={category.name} />
            </ListItem>
            {index < categories.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default BlogCategories;
