import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BlogCategories from './BlogCategories';
import PostContent1 from './posts/Post1';
import PostContent2 from './posts/Post2';
import PostContent3 from './posts/Post3';
import PostContent4 from './posts/Post4';
import PostContent5 from './posts/Post5';
import PostContent6 from './posts/Post6';
import PostContent7 from './posts/Post7';
import PostContent8 from './posts/Post8';
import PostContent9 from './posts/Post9';
import PostContent10 from './posts/Post10';

const BlogPost = ({ postContent, isLiked, onLike }) => (
  <Paper sx={{ padding: '20px', marginBottom: '20px', position: 'relative' }}>
    {postContent}
    <IconButton
      onClick={onLike}
      sx={{ marginTop: '10px' }} 
      color={isLiked ? 'primary' : 'default'}
      aria-label="like post"
    >
      <ThumbUpIcon />
    </IconButton>
  </Paper>
);

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [likedPosts, setLikedPosts] = useState([]);

  useEffect(() => {
    const storedLikes = JSON.parse(localStorage.getItem('likedPosts')) || [];
    setLikedPosts(storedLikes);
  }, []);

  const posts = [
    {
      id: 'Post1',
      content: PostContent1,
      category: 'Tecnologia Solar Fotovoltaica',
      date: '2024-08-21',
    },
    {
      id: 'Post2',
      content: PostContent2,
      category: 'Tecnologia Solar Fotovoltaica',
      date: '2024-08-23'
    },
    {
      id: 'Post3',
      content: PostContent3,
      category: 'Sustentabilidade',
      date: '2024-08-23'
    },
    {
      id: 'Post4',
      content: PostContent4,
      category: 'Sustentabilidade',
      date: '2024-08-22'
    },
    {
      id: 'Post4',
      content: PostContent5,
      category: 'Políticas e Incentivos Governamentais',
      date: '2024-08-23'
    },
    {
      id: 'Post6',
      content: PostContent6,
      category: 'Políticas e Incentivos Governamentais',
      date: '2024-08-24'
    },
    {
      id: 'Post7',
      content: PostContent7,
      category: 'Economia e Investimento',
      date: '2024-08-25'
    },
    {
      id: 'Post8',
      content: PostContent8,
      category: 'Economia e Investimento',
      date: '2024-08-27'
    },
    {
      id: 'Post9',
      content: PostContent9,
      category: 'Políticas e Incentivos Governamentais',
      date: '2024-09-08'
    },
    {
      id: 'Post10',
      content: PostContent10,
      category: 'Tecnologia Solar Fotovoltaica',
      date: '2024-09-11'
    }
  ];

  const filteredPosts = selectedCategory
    ? posts.filter(post => post.category === selectedCategory)
    : posts;

  // Ordena os posts pela data mais recente
  const sortedPosts = filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

  const isPostLiked = (id) => likedPosts.includes(id);

  const handleLikePost = async (postId) => {
    try {
      const apiUrl =  `${process.env.REACT_APP_API_URL}/SimuladorFV`
      const response = await fetch(`${apiUrl}/LikePost?postid=${postId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postId }),
      });
      if (response.ok) {
        console.log(`Post ${postId} liked successfully`);
        const updatedLikedPosts = [...likedPosts, postId];
        setLikedPosts(updatedLikedPosts);

        // Armazena as curtidas atualizadas no localStorage
        localStorage.setItem('likedPosts', JSON.stringify(updatedLikedPosts));
      } else {
        console.error(`Failed to like post ${postId}`);
      }
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ marginTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <BlogCategories onSelectCategory={setSelectedCategory} />
          </Grid>
          <Grid item xs={12} md={9}>
            {sortedPosts.map((post, index) => (
              <BlogPost
              key={index}
              postContent={<post.content onLike={() => handleLikePost(post.id)} />}
              isLiked={isPostLiked(post.id)}
              onLike={() => handleLikePost(post.id)}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Blog;