import React, { lazy, Suspense, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card, CardContent } from '@mui/material';
import FormSection from './FormSection';
import MarketingSection from './MarketingSection';
import AdsComponent from './AdsComponent';

const ResultTabs = lazy(() => import('./ResultTabs'));

function Simulator() {
    const [formData, setFormData] = useState({
        estado: '',
        municipio: '',
        tipo: "Residencial",
        temEnergia: true,
        tarifaComImposto: 0,
        distribuidora: '',
        consumoMensal: 0,
    });
    const [initial, setinitial] = useState(true);


    const [simulationResult, setSimulationResult] = useState({
        fatorKWhKWp: 0,
        potencia: 0,
        geracaoMensalMedia: 0,
        geracaoAnual: 0,
        area: 0,
        paybackMeses: 0,
        economiaAnual: 0,
        custoMin: 0,
        custoMax: 0,
        perdas: 0,
        numeroPlacas: 0,
        pesodasPlacas: 0,
        potenciaPlaca: 0,
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setwarningMessage] = useState('');

    const iframes = [
        "https://ler.amazon.com.br/kp/card?asin=B07DQQFX6N&preview=inline&linkCode=kpe&ref_=kip_embed_taf_preview_212FNHVJFE7RYY51HXC6&tag=fcoliveira-20"
        // Adicione outros URLs de iframes aqui
    ];

    const [currentIframeIndex, setCurrentIframeIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIframeIndex((prevIndex) => (prevIndex + 1) % iframes.length);
        }, 5000); // Troca de iframe a cada 5 segundos

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, [iframes.length]);

    const handleFormSubmit = async (data) => {

        try {
            const consumoMensal = (data.consumoMensal ? data.consumoMensal : 0);

            const request = {
                "UF": data.estado,
                "Municipio": data.municipio,
                "Tarifa": data.tarifaComImposto,
                "ConsumoEnergiaMensal": consumoMensal
            };

            const apiUrl =  `${process.env.REACT_APP_API_URL}/SimuladorFV`
            const response = await fetch(`${apiUrl}/Simulate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                console.error('Erro ao realizar simulação.');
            }
            else {
                const result = await response.json();

                if (!data.temEnergia)
                    setwarningMessage('Tarifas extras podem ser cobradas pela concercionária.');

                setSimulationResult(result);
                setinitial(false);
            }

            //TODO: Incluir link ANEEL
        } catch (error) {
            setErrorMessage('Erro ao realizar a simulação.');
        }
    };

    return (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <FormSection formData={formData} setFormData={setFormData} onSubmit={handleFormSubmit} />
                </Grid>
                <Grid item xs={12} md={8}>
                    {initial ? (
                        <MarketingSection />
                    ) :
                        (
                            <Suspense>
                                <Grid item xs={12} md={10}>
                                    <ResultTabs simulationResult={simulationResult} warningMessage={warningMessage} errorMessage={errorMessage} />
                                </Grid>
                            </Suspense>
                        )}
                </Grid>
                <Grid item xs={12} md={2}>
                    <Card elevation={0}>
                        <CardContent>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '80%',
                                    maxWidth: '336px',
                                    height: '0',
                                    paddingBottom: 'calc(550 / 336 * 100%)',
                                    overflow: 'hidden',
                                    border: 'none'
                                }}
                            >
                                <iframe
                                    title="Anúncio"
                                    sandbox="allow-scripts allow-same-origin allow-popups"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: 'none'
                                    }}
                                    src={iframes[currentIframeIndex]}
                                    allowFullScreen
                                />
                            </Box>
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardContent>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 'auto',
                                    textAlign: 'center'
                                }}
                            >
                                <AdsComponent />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}
export default Simulator;