import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';

function SelectionComponent ({ onSelect }) {
  const [selected, setSelected] = useState('Residencial');

  const handleSelect = (option) => {
    setSelected(option);
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={1} marginBottom={1}>
      <Box
        onClick={() => handleSelect('Residencial')}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          color: selected === 'Residencial' ? 'primary.main' : 'text.secondary',
        }}
      >
        <HomeIcon sx={{ fontSize: 30 }} />
        <Typography variant="h6" sx={{ fontSize: '0.8rem' }}>Residencial</Typography>
      </Box>

      <Box
        onClick={() => handleSelect('Empresarial')}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          ml: 4,
          color: selected === 'Empresarial' ? 'primary.main' : 'text.secondary',
        }}
      >
        <BusinessIcon sx={{ fontSize: 30 }} />
        <Typography variant="h6" sx={{ fontSize: '0.8rem' }}>Empresarial</Typography>
      </Box>
    </Box>
  );
}

export default SelectionComponent;
