import React from 'react';
import { Box, Typography } from '@mui/material';

const MarketingSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 4,
      }}
    >      
        <img 
          src={ "/solarsystemmkt.jpg"}
          alt="Sistema Fotovoltaico."
          loading="lazy"
          style={{
            maxWidth: '100%', 
            height: 'auto'
          }}
          width="480" // Substitua com a largura real da sua imagem
          height="320" // Substitua com a altura real da sua imagem 
        />
      <Typography variant="h4" component="h1" sx={{ marginTop: 2 }}>
        Simulador Fotovoltaico 
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2, maxWidth: '800px' }}>
        Descubra como você pode economizar com energia solar utilizando nosso simulador de sistema fotovoltaico. 
        Com a crescente demanda por soluções de energia sustentável, nosso simulador permite que você calcule a 
        eficiência e os benefícios de instalar painéis solares em sua residência ou empresa. Maximize suas economias 
        e contribua para um futuro mais verde. Experimente agora e veja como a energia solar pode transformar sua 
        conta de luz.
      </Typography>
    </Box>
  );
};

export default MarketingSection;
