import { createTheme } from '@mui/material/styles';

const lightTheme  = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#029356', // A cor que você deseja para a cor primária
    },
    secondary: {
      main: '#009EB0', // A cor que você deseja para a cor secundária
    },
  },
  typography: {
    body1: {
      color: '#00000', // A cor que você deseja para a tipografia body1
    },
    body2: {
      color: '#00000', // A cor que você deseja para a tipografia body2
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#25D366', // A cor que você deseja para a cor primária
    },
    secondary: {
      main: '#F3BA2F', // A cor que você deseja para a cor primária
    },
  },
});

export {lightTheme, darkTheme};
