import React from 'react';
import { Grid, Box, Typography, Avatar, Card, CardContent, Link, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RecentUpdates from './Updates';

export default function ProjectInfo() {
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Dimensionamento de Sistemas Fotovoltaicos
              </Typography>
              <Typography variant="body1" gutterBottom>
                Este site oferece um serviço gratuito para dimensionamento de sistemas fotovoltaicos, permitindo que os usuários façam simulações personalizadas sem custos. Aproveite para utilizar esta ferramenta e ajudar no planejamento de seu projeto de energia solar.
              </Typography>

              <Typography variant="body2" color="textSecondary" gutterBottom>
                Data de lançamento: 01 de Setembro de 2024
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
                <Avatar
                  alt="Foto do Desenvolvedor"
                  src="/developer-photo.jpg"
                  sx={{ width: 120, height: 120, borderRadius: '50%' }}
                />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Desenvolvedor</Typography>
                  <Typography variant="body2" fontWeight="bold">Fernando Oliveira</Typography>
                  <Typography variant="body2">Este projeto é uma iniciativa pessoal e está disponível gratuitamente para a comunidade.</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                    <Link
                      href="https://www.linkedin.com/in/fernando-oliveira-9b328526/"
                      target="_blank"
                      rel="noopener"
                      sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                    >
                      <IconButton color="primary" size="large">
                        <LinkedInIcon />
                      </IconButton>
                      <Typography variant="body2" color="primary">
                        Conecte-se no LinkedIn
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentUpdates />
        </Grid>
      </Grid>
    </>
  );
};