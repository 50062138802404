import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function SolarEstimationProcedure() {
  return (
    <Container>
      <Box sx={{ margin: '20px 0' }}>
        <Typography variant="h5" gutterBottom>
          Procedimento de Cálculo para Estimativa de Geração de um Sistema Fotovoltaico On-GRID
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Irradiância Solar
        </Typography>
        <Typography variant="body1" gutterBottom>
          A irradiância solar é a quantidade de energia solar recebida por unidade de área, geralmente medida em kWh/m²/dia. Este valor varia conforme a localização e a estação do ano.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Eficiência dos Painéis
        </Typography>
        <Typography variant="body1" gutterBottom>
          A eficiência dos painéis solares é a capacidade deles de converter a luz solar em eletricidade. Esta eficiência é geralmente expressa em porcentagem.
          Por exemplo, um painel com eficiência de 20% converte 20% da energia solar recebida em eletricidade. O valor deste indicador pode ser encontrado no datasheet dos módulos.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Seleção dos módulos
        </Typography>
        <Typography variant="body1" gutterBottom>
          Este simulador possui um banco de dados atualizado com algumas placas dentre as mais utilizadas do mercado. O sistema automaticamente seleciona o modelo, potência e a quantidade das placas garantindo uma produção de energia mais próxima do consumo.
          O simulador, portanto, considera parâmetros reais do equipamento incluindo o indicador de Eficência informado pelo fabricante.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Perdas do Sistema
        </Typography>
        <Typography variant="body1" gutterBottom>
          As perdas do sistema incluem vários fatores, entre eles : sombreamento, poeira, aquecimento dos painéis, posicionamento e inclinação das placas, eficiência do inversor, perdas nos condutores e diferença de tensão entre strings. Essas perdas geralmente somam entre 10% a 20% do total.
          Este simulador considera o total de perdas igual a 20%.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Tamanho do Sistema
        </Typography>
        <Typography variant="body1" gutterBottom>
          O tamanho do sistema fotovoltaico é a potência máxima dos painéis instalados em condições ideais, a unidade de medida é kilo Watts pico (kWp).
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Cálculo da Geração Estimada
        </Typography>
        <Typography variant="body1" gutterBottom>
          A fórmula para calcular a geração estimada de energia é:
        </Typography>
        <Typography variant="body1" gutterBottom style={{ marginTop: '16px' }}>
         Geração Estimada (kWh/dia) = Irradiância Solar (kWh/m²/dia) * Área dos Painéis (m²) * Eficiência dos Painéis (%) * (1 - Perdas do Sistema (%))
        </Typography>
        <Typography variant="body1" gutterBottom style={{ marginTop: '16px' }}>
          Para um sistema de 8 painéis com potência de 455 Wp, área de 2,21m² e eficiência de 20,6%, a potência total do sistema é 3,64 KWp e a área total é 17,68 m².
          Considerando que este sistema será instalado em um local onde a Irradiância Solar é em média 5,515 kWh/m²/dia:
        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }} gutterBottom>
          Geração Estimada (kWh/dia) = 5,515 (kWh/m²/dia) * 17,68 m² * 20,6% * (1 - 20%) = 16 kWh/dia ou 5865 kWh/ano
        </Typography>
      </Box>
    </Container>
  );
}

export default SolarEstimationProcedure;
