import { Typography, Box } from '@mui/material';

const PostContent5 = () => {

 
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Incentivos Fiscais para Energia Solar no Brasil
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Publicado em: 23/08/2024
      </Typography>
      <Box mt={2} mb={2}>
        <img
          src="https://www.gov.br/fundaj/pt-br/destaques/observa-fundaj-itens/observa-fundaj/credito-rural-adaptado-a-regioes-secas/xblue.jpg.pagespeed.ic.gOvGFIYf.jpg"
          alt="FUNDAJ GOV.BR"
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>
      <Typography variant="body1" paragraph>
        No Brasil, os incentivos fiscais para a energia solar desempenham um papel vital na promoção da adoção dessa tecnologia. O governo oferece uma série de benefícios, como a isenção de impostos sobre a importação de equipamentos solares, o que reduz significativamente o custo inicial de instalação.
      </Typography>
      <Typography variant="body1" paragraph>
        Além disso, há programas estaduais que oferecem isenção de ICMS na compra de equipamentos solares e na venda de energia gerada a partir de fontes renováveis. Essas medidas não apenas tornam a energia solar mais acessível, mas também incentivam a transição para fontes de energia mais limpas e sustentáveis.
      </Typography>
      <Typography variant="body1" paragraph>
        Outro incentivo importante é o sistema de compensação de energia (net metering), que permite aos consumidores abaterem da sua conta de luz o valor correspondente à energia gerada e injetada na rede. Isso cria um retorno financeiro direto para os proprietários de sistemas solares, acelerando o retorno sobre o investimento.
      </Typography>
      <Typography variant="body1" paragraph>
        Com essas políticas, o Brasil tem visto um crescimento expressivo na adoção de energia solar, tanto no setor residencial quanto no comercial. Os incentivos fiscais são, portanto, um dos pilares fundamentais para a expansão da energia solar no país.
      </Typography>
    </div>
  );
};

export default PostContent5;
