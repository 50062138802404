import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SimFVAppBar from './components/SimFVAppBar';
import Toolbar from '@mui/material/Toolbar';
import Simulator from './components/Simulator'
import SolarEstimationProcedure from './components/SolarEstimationProcedure';
import Blog from './components/Blog';
//import Store from './components/amazon/Store';
import Project from './components/Project';
//import PartnerRegistrationForm from './components/PartnerRegistrationForm';
//import SuggestionsForm from './components/SuggestionsForm';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const savedMode = localStorage.getItem('mode');
  const [mode, setMode] = React.useState(savedMode || 'light');
  
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('mode', newMode); // Save mode to localStorage
      },
    }),
    [mode],
  ); 

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={mode ==='dark' ? darkTheme : lightTheme}>
        <CssBaseline />
        <Box >
          <Router>
            <SimFVAppBar colorModeContext={ColorModeContext} />
            <Toolbar position="fixed" sx={{ height: 55 }}/> {/* This empty Toolbar component adds the necessary space below the AppBar */}
            <Routes>
              <Route path="/" element={<Simulator />} />
              <Route path="/funcionamento" element={<SolarEstimationProcedure />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/loja" element={<Blog />} />
              <Route path="/projeto" element={<Project />} />
              {/*<Route path="/sugestoes" element={<SuggestionsForm />} />*/}
              {/*<Route path="/parceiros" element={<PartnerRegistrationForm />} />  */}         
            </Routes>
          </Router>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
